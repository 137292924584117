<template>
  <div class="px-8 mt-4">
        <v-dialog
          v-model="remarksDialog"
          scrollable
          max-width="750px"
          persistent
        >
          <v-card>
            <v-toolbar flat>
              <v-toolbar-title>
                Status and Messages
              </v-toolbar-title>

              <v-spacer></v-spacer>
              <v-btn icon @click="reloadRemarks()">
                <v-icon>mdi-reload</v-icon>
              </v-btn>
              <v-btn icon @click="closeDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider class="dividerClass"></v-divider>
            <v-card-text style="max-height: 800px;"  class="py-0 px-5 overflow-y-auto">
            <v-timeline
                dense
                v-if="remarksData.length > 0"
              >
                <v-timeline-item
                  v-for="remarks in remarksData"
                  :key="remarks.RemarksID"
                  :color="remarks.isMessage ? 'amber accent-4' : remarks.StatusID == 7 ? 'header' : 'red darken-2'"
                >
                  <v-card class="elevation-2" v-if="remarks.isMessage">
                    <v-card-title>
                      {{remarks.fullname}}
                      <v-spacer></v-spacer>
                      <span class="font-size-12">{{formatDateTime(remarks.DateCreated)}}</span>
                    </v-card-title>
                    <v-card-text class="font-size-18">
                      {{remarks.Message}}
                    </v-card-text>
                  </v-card>
                  <v-alert
                    v-else
                    :value="true"
                    :color="remarks.StatusID == 7 ? 'header' : 'red darken-2'"
                    :icon="remarks.StatusID == 7 ? 'mdi-check-circle-outline' : 'mdi-clock-outline'"
                    class="white--text"
                    outlined
                    border="left"
                    prominent
                  >
                  <v-row align="center">
                    <v-col cols="12" md="9">
                      <div v-if="remarks.StatusID == 7"><b>{{remarks.RoleDescription != 'User' ? remarks.RoleDescription : 'Recommending Approval'}} approved the {{ data.DocType == 1 ? 'PPMP' : data.DocType == 2 ? 'Purchase Request' : data.DocType == 3 ? 'Purchase Order' : ''}}.</b></div>
                      <div v-if="remarks.StatusID == 0"><b>{{remarks.RoleDescription != 'User' ? remarks.RoleDescription : 'Recommending Approval'}} pending the {{ data.DocType == 1 ? 'PPMP' : data.DocType == 2 ? 'Purchase Request' : data.DocType == 3 ? 'Purchase Order' : ''}}.</b></div>
                      {{remarks.Message}}
                    </v-col>
                    <v-col cols="12" md="3">
                      <span class="font-size-12">{{formatDateTime(remarks.DateCreated)}}</span>
                    </v-col>
                  </v-row>
                  </v-alert>
                </v-timeline-item>
              </v-timeline>
            <div class="mt-10 mb-8 text-subtitle-1" align="center" v-if="remarksData.length == 0">No Messages</div>
            </v-card-text>
            <v-card-actions>
              <v-text-field
                v-model="message"
                append-outer-icon="mdi-send"
                outlined
                clear-icon="mdi-close-circle"
                clearable
                label="Message"
                type="text"
                hide-details
                @click:append-outer="sendMessage"
                @click:clear="clearMessage"
                class="mb-3"
                color="amber accent-4"
              ></v-text-field>
            </v-card-actions>
          </v-card>
        </v-dialog>
  </div>
</template>
    
    <script>
export default {
  props: {
    data: {},
  },
  data: () => ({
    remarks: null,
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
    },
    remarksDialog: false,
    remarksData: [],
    message: '',
    unseenMsgs: 0,
  }),
  watch: {
    data: {
      handler(data) {
        if (data.id) {
          this.getRemarksByID();
           this.remarksDialog = true
        }
      },
      deep: true,
    },
  },
  methods: {
    sendMessage () {
      let data = new FormData();
        data.append("Message", this.message);
        data.append("UserID", this.$store.state.user.UserID);
        data.append("ParentID", this.data.RemarksID ? this.data.RemarksID : 0);
        data.append("DocType", this.data.DocType);
        data.append("DocTypeID", this.data.id);
        data.append("isMessage", 1);
        this.axiosCall("/remarks/send", "POST", data).then((res) => {
          if(!this.data.RemarksID){
            this.data.RemarksID = res.data.data
          }
          this.getRemarksByID();
          this.clearMessage()
        });
    },
    clearMessage () {
      this.message = ''
    },
    reloadRemarks(){
      this.getRemarksByID();
    },
    closeDialog() {
      this.remarksDialog = false;
      this.eventHub.$emit("closePSURemarks", false);
    },
    viewRemarks(){
      this.remarksDialog = true;
      if(this.unseenMsgs > 0){
        let data = new FormData();
        data.append("RemarksID", this.data.RemarksID);
        this.axiosCall("/remarks/seen", "POST", data).then((res) => {
          console.log(res)
          this.unseenMsgs = 0
        });
      }
    },
    getRemarksByID() {
        let data = new FormData();
        data.append("RemarksID", this.data.RemarksID);
        this.axiosCall("/getRemarksByID", "POST", data).then((res) => {
        this.remarksData = res.data.data
      });
    },
  },
};
</script>
        
    <style scoped>
.v-dialog--fullscreen,
.prModal {
  overflow-x: hidden !important;
}
.dividerClass {
   border-width: 1px !important;
}
</style>